define(function (require, exports, module) {
    var form = function (config) {
        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {
            name: 'form'
        }, config)

        opt.id = config.id;
        opt.dom = $("[esapp-id='" + config.id + "']");
        opt.validate = function () {
            var res = true
            _(this.items).each(function (item) {
                if(!item){
                    return;
                }
                if(!item.validate() || !res){
                    res = false
                    return false;
                }
                // return res = item.validate() && res
            })
            return res
        }

        opt.clearInvalid = function(){

        }

        opt.getItemById = function(id) {
            var res = null
            $.each(this.items, function(_, v) {
                if (v.dom.attr('name') == id || v.id == id) {
                    res = v
                    return false
                }
            })
            return res
        }

        opt.getValue = function(){
            var items = config.items,
                data = {};
            _.each(items,function(v,k){
                if(!v){
                    return;
                }
                var name = $(v.dom).attr("name");
                if(typeof v.getValue() == 'object' && !_.isArray(v.getValue())){
                    data = _.merge(data,v.getValue())
                }else {
                    data[name] = v.getValue()
                }
            })
            return data;
        }

        opt.setValue = function(res){
            var items = config.items,
                data = {};
            _.each(items,function(v,k){
                var name = $(v.dom).attr("name");
                data[name] = v.setValue(res[name])
            })
            return data;
        }

        opt.clearValue = function(){
            var items = config.items,
                data = {};
            _.each(items,function(v,k){
                var name = $(v.dom).attr("name");
                data[name] = v.clearValue()
            })
            return data;
        }

        opt._bindEven = function(){
            this.dom.unbind("keydown");
            this.dom.on("keydown",function(e){
                var target,
                    keyCode

                e = e || window.event || arguments.callee.caller.arguments[0]
                target = e.target || e.srcElement
                keyCode = e.keyCode || e.which

                if(keyCode !== 13) {
                    return
                }else{
                    if(config.enterEvent){
                        config.enterEvent()
                    }
                }

                // TODO 添加额外的判断条件
                if('select' === target.tagName.toLowerCase()) {
                    return
                }

                $("[esapp-id='" + config.submitBtn + "']").trigger('click')
            })
        }
        opt.init = function(){
            opt._bindEven();
            return this;
        }
        return opt.init()
    }
    return form
})
