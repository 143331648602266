// define(function () {
// })

// init ui namespace
if (!window.esapp) {
    window.esapp = {}
}
window.esapp.ui = {}
window.esapp.ui.defaultConfig = {
    name: '',
    allowBlank: true,
    charMask: '',
    disabled: false,
    readonly: true,
    id: null,
    dom: null,
    customerValidate: [],

    defaultInit: function () {
        if (!this.dom) this.dom = $("[esapp-id='" + this.id + "']")
        if (this.$ele) this.dom = this.$ele
        if (this.defaultValue) {
            this.setValue(this.defaultValue)
        }
    },
    bindValidation: function () {
        //根据参数挂上需要验证的方法
        var allValidate = []
        var validationList = this.validations || []
        if (this.nonempty) validationList.unshift('isEmpty')
        _(validationList || []).each(function (item) {
            allValidate.push(window.esapp.validation(item))
        })
        this.customerValidate = allValidate
        //绑定即使验证
        var validate = this.validate
        var scope = this
        this.dom.on('keyup', function () {
            // validate.call(scope)
        })
        if(this.dom[0] && this.dom[0].nodeName == "INPUT"){
            this.dom.on('focus', function () {
                scope.hideInfoMsg()
            })
        }
        return {
            validate: validate,
            scope: scope
        }
    },
    defaultChangeEvt: function () {
        //只能输入数字
        if (this.dom.hasClass('number')) {
            var dom = this.dom
            this.get_value = function () {
                var v = dom.val()
                return $.isNumeric(v) ? +v : 0
            }
            dom.on('change', function () {
                var v = $(this).val()
                if (!$.isNumeric(v)) {
                    v = parseFloat(v)
                    if (!v) {
                        v = ""
                    }
                    $(this).val(v)
                }
            })
            dom.on('keyup', function (e) {
                dom.change()
            })
        }
    },
    init: function () {
        this.defaultInit()
        this.bindValidation()
        this.defaultChangeEvt()
        return this
    },
    getName: function () {
        return this.name
    },
    getId: function () {
        return this.id
    },
    show: function () {
    },
    hide: function () {
    },
    enable: function () {
        this.dom.prop('disabled', false)
    },
    disable: function () {
        this.dom.prop('disabled', true)
    },
    setReadonly: function (readonly) {
    },
    getValue: function () {
        var type = 'text'
        if(this.dom[0]) type = this.dom[0].type
        switch (type) {
            case 'checkbox':
                return this.dom[0].checked
                break
            default:
                return this.dom.val() ? this.dom.val().trim() : '';
        }
    },
    setValue: function (value) {
        if (!value) return
        var type = 'text'
        if(this.dom[0]) type = this.dom[0].type
        switch (type) {
            case 'checkbox':
                this.dom[0].checked = value
                break
            default:
                this.dom.val(value)
        }
        this.validate()
    },
    showErrorMsg: function (msg) {
    },
    hideErrorMsg: function () {
    },
    showWarningMsg: function (msg) {
    },
    hideWarningMsg: function () {
    },
    showInfoMsg: function (msg) {
        this.hideInfoMsg()
        this.dom.closest('.input-group').addClass('has-error')
        msg = esapp.msg.get(msg)
        if (this.isPopup) {
            this.dom.closest('.modal-content').find('.popup-error-msg').html(msg)
        }else if(this.msg_type == "tooltip"){
            this.dom.closest('.input-group').find('.msg-tip').remove()
            $(this.dom).parent().append('<div class="msg-tip error" data-direction="top">'+ msg +'</div>')
        }  else {
            var template = Handlebars.compile('<span class="badge bg-danger"><i class="fa fa-exclamation-circle"></i> {{msg}}</span>')
            this.dom.parent().after(template({ msg: msg }))
        }
    },
    hideInfoMsg: function () {
        if (this.isPopup) {
            this.dom.closest('.modal-content').find('.input-group').removeClass('has-error')
            this.dom.closest('.modal-content').find('.popup-error-msg').html('')
        } else if(this.msg_type == "tooltip" ){
            this.dom.closest('.input-group').removeClass('has-error')
            this.dom.closest('.input-group').find('.msg-tip').remove()
        } else {
            this.dom.closest('.input-group').removeClass('has-error')
            this.dom.parent().siblings('.badge').remove()
        }
    },
    showSuccessMsg: function (msg) {
    },
    hideSuccessMsg: function () {
    },
    clearError: function () {
    },
    clearValue: function (allowBlank) {
        this.dom.val('')
        this.validate()
    },
    _singleValidate: function (msg) {
        var allValidate = true
        for (var i = 0; i < this.customerValidate.length; i++) {
            var res = this.customerValidate[i].validate(msg)
            if (!res) {
                this.showInfoMsg(this.customerValidate[i].name)
                allValidate = false
                break
            } else {
                this.hideInfoMsg()
            }
        }
        return allValidate
    },
    validate: function () {
        var value = this.getValue()
        var scope = this
        if (!value) {
            value = '';
        }
        switch (Object.prototype.toString.call(value)) {
            case '[object Array]':
                var result = true
                if (value.length) {
                    _(value).each(function (item) {
                        result = result && scope._singleValidate.call(scope, item)
                    })
                } else {
                    result = result && scope._singleValidate.call(scope, value[0])
                }
                return result
            case '[object String]':
                return scope._singleValidate(value.trim())
            default:
                return true
        }
    },
    clearInvalid: function () {

    },
    setNonempty: function (nonempty) {
        this.nonempty = nonempty
        if(nonempty) {
            this.customerValidate.push(window.esapp.validation('isEmpty'))
        } else {
            this.customerValidate.forEach(function(item, index) {
                if(item.name == 'isEmpty') {
                    this.customerValidate.splice(index, 1)
                    return false
                }
            }, this)
        }
    },
    setWidth: function (width) {
        this.dom.closest('.form-group').css({
            width: width || '33%',
            float: 'left'
        })
    }
}

window.esapp.validation = function (validationName) {
    var res = {
        name: validationName
    }
    if ('isEmpty' == validationName) {
        res.validate = function (str) {
            switch (Object.prototype.toString.call(str)) {
                case '[object Number]':
                    return str
                case '[object String]':
                    return str.trim() ? true : false
                case '[object Undefined]':
                    return false
                default:
                    return true
            }
        }
    } else {
        res.validate = function (str) {
            var isLegal = true
            var reg = window.esapp.regular[validationName]
            if (str.trim()) {
                isLegal = reg.test(str)
            }
            return isLegal
        }
    }
    return res
}

window.esapp.regular = {
    isEmail: /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/,
    isPhone: /^(\d{3,4}-)?\d{6,8}(-\d{3})?$/,
    isMobile: /^\S*(13[0-9]|15[0|1|2|3|5|6|7|8|9]|18[0|6|8|9]|14[5|7]|17[0|6|7|8]|18[1|2|3|4|5]|187)\d{8}$/,
    isNatureNumber: /^[0-9]*[1-9][0-9]*$/,
    isFloatNumThree: /^[0-9]*.[0-9]{3}$/,
    isFloatNumTwo: /^[0-9]*.[0-9]{2}$/,
    isIDCardNumber: /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/,
    isPlateNumber: /^[\u4E00-\u9FA5][\da-zA-Z]{6}$/,
    isPassword: /^[\s\S]{6,20}$/,
    isZipCode: /^[1-9][0-9]{5}$/,
    exceptChinese: /^[^\u4e00-\u9fa5]*$/,
    english: /^([A-Za-z0-9 ,.']+\s?)*[A-Za-z0-9 ,.']/,
    chinese: /[\u4e00-\u9fa5]/,
    ismyReg: /^[0-9a-zA-Z]*$/,

}
