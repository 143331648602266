define(function (require, exports, module) {
    var date = function (config) {
        var configCn = {
            dateFormat: 'yy-mm-dd',
            yearSuffix: '年',
            todayHighlight: true,
            monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
            dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            dayNamesShort: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            dayNamesMin: ['日', '一', '二', '三', '四', '五', '六']
        }

        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {
            name: 'date',
            init: function () {
                this.defaultInit()
                this.bindValidation()
                this.defaultChangeEvt()

                config = _.merge(config, configCn)

                this.startDate = $("[esapp-id=" + config.startId + "]")
                this.endDate = $("[esapp-id=" + config.endId + "]")

                this.startDate.on('change', function (e,res) {
                    if (res === 'clear') {
                        opt.start_event_chage("");
                        return
                    }
                    var n = $(this).val();
                    var reg = /^((?:19|20)\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
                    if (!reg.test(n)) {
                        $(this).val("");
                        opt.endDate.val('')
                        return
                    }
                    if (!opt.isSetValue) {
                        var startTime = opt.startDate.val()
                        var endTime = opt.endDate.val()
                        if (new Date(startTime) > new Date(endTime) || !endTime) {
                            opt.endDate.val(startTime)
                            if (!config.noTime && startTime) {
                                startTime = new Date(startTime);
                                startTime.setHours(23);
                                startTime.setMinutes(59);
                                startTime.setSeconds(59);
                                startTime = _.util.date_to_string(startTime, "{{y}}-{{m}}-{{d}} {{h}}:{{M}}:{{s}}", true)
                            }
                            if (opt.end_event_chage) {
                                opt.end_event_chage(startTime);
                            }
                        }
                    }
                })
                this.endDate.on('change', function (e,res) {
                    if (res === 'clear') {
                        opt.end_event_chage("");
                        return
                    }
                    var n = $(this).val();
                    var reg = /^((?:19|20)\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
                    if (!reg.test(n)) {
                        $(this).val("");
                        opt.startDate.val('')
                        return
                    }
                    if (!opt.isSetValue) {
                        var startTime = opt.startDate.val()
                        var endTime = opt.endDate.val()
                        if (new Date(startTime) > new Date(endTime) || !startTime) {
                            opt.startDate.val(endTime)
                            if (opt.start_event_chage) {
                                opt.start_event_chage(endTime);
                            }
                        }
                    }
                })
                var stDate = esWidget.esDate({
                    id: config.startId,
                    onChange: function (res) {
                        if (opt.start_event_chage) {
                            opt.start_event_chage(res);
                        }
                    }
                })
                var stDate = esWidget.esDate({
                    id: config.endId,
                    onChange: function (res) {
                        if (!config.noTime && res) {
                            res = new Date(res);
                            res.setHours(23);
                            res.setMinutes(59);
                            res.setSeconds(59);
                            res = _.util.date_to_string(res, "{{y}}-{{m}}-{{d}} {{h}}:{{M}}:{{s}}", true)
                        }
                        if (opt.end_event_chage) {
                            opt.end_event_chage(res);
                        }
                    }
                })
                // }
                return this
            },
            setValue: function (val) {
                opt.isSetValue = true
                this.startDate.newDatePicker('setDate', val.startDate)
                this.endDate.newDatePicker('setDate', val.endDate)
                opt.isSetValue = false
            },
            getValue: function () {
                var obj = {}
                var startTime = this.startDate.val();
                var endTime = this.endDate.val();
                if (!config.noTime && endTime) {
                    endTime = new Date(endTime);
                    endTime.setHours(23);
                    endTime.setMinutes(59);
                    endTime.setSeconds(59);
                    endTime = _.util.date_to_string(endTime, "{{y}}-{{m}}-{{d}} {{h}}:{{M}}:{{s}}", true)
                }

                obj[this.startDate.attr('name')] = startTime;
                obj[this.endDate.attr('name')] = endTime;
                return obj;
            },
            clearValue: function (allowBlank) {
                this.startDate.val('')
                this.endDate.val('')
            }
        }, config)
        return opt.init()
    }
    return date
})