require('../widget/core.js')
window.esWidget = {
    esInput: require('../widget/input.js'),
    esInputFilter: require('../widget/input-filter.js'),
    esRadio: require('../widget/radio.js'),
    // esCheckBox: require('../widget/checkbox.js'),
    esDate: require('../widget/date.js'),
    esDateToDate: require('../widget/dateToDate.js'),
    esForm: require('../widget/form.js'),
    // esGeo: require('../widget/geo.js'),
    // esMap: require('../widget/map.js'),
    esPaging: require('../widget/paging.js'),
    esSelect2: require('../widget/select2.js'),
    // esTable: require('../widget/table.js'),
    esMenu: require('../widget/menu.js'),
    // esGridTable: require('../widget/grid-table.js')
}




