define(function (require, exports, module) {
    var input = function (config) {

        var opt = _.clone(esapp.ui.defaultConfig)
        opt._initConfig = function (config) {
            _.merge(opt, {
                name: 'input-filter'
            }, config)

            opt.isAsync = opt.isAsync || false
            opt.data = opt.data ? opt.data : []
            opt.validate_display = opt.validate_display
            opt.value_field = opt.value_field ? opt.value_field : function() {
                return ''
            }
            opt.display_field = opt.display_field ? opt.display_field : function() {
                return ''
            }
            opt.filter = opt.filter ? opt.filter : function() {
                return []
            }
        }
        // console.log(config)
        opt.getValue = function() {

            if(opt.dom.data('value')) {
                var val = opt.dom.data('value')
                var text = opt.dom.val().trim()
                var value = ''
                opt.data.forEach(function(item) {
                    if(item.id == val && item.text == text) value = val
                })
                if(value) return value
            }
            if(opt.allow_enter){
                if( opt.data.length > 0 ){
                    var has_val = false
                    $.each(opt.data,_.delegate(function(_,v){
                        if(!!this.dom.val() && opt.display_field(v) == this.dom.val()){
                            this.dom.data('value', opt.value_field(v))
                            has_val = true
                            return false
                        }
                    },this))
                    if(!has_val){
                        this.dom.data('value', '')
                    }
                }else {
                    this.dom.data('value', '')
                }
                return this.dom.data('value') || (this.dom.val() ? this.dom.val().trim() : '') || ''
            } else {
                return this.dom.data('value') || '' //不允许输入时不应取文本框的value
            }
        }

        opt.get_display_value = function() {
            return this.dom.val() ? this.dom.val().trim() : '';
        }
        opt.set_display_value = function(val) {
            return this.dom.val(val)
        }
        opt.setValue = function(val) {
            if(opt.data.length>0){
                var has_val = false
                $.each(opt.data,_.delegate(function(_,v){
                    if(opt.value_field(v)==val){
                        this.dom.data('value', val)
                        this.dom.val(opt.display_field(v))
                        has_val = true
                        return false
                    }
                },this))
                if(!has_val){
                    this.dom.val(val)
                    this.dom.data('value', val)
                }
            }else {
                this.dom.val(val)
                this.dom.data('value', val)
            }
            if(this.dom.hasClass('change-input')) this.dom.trigger('change')
            return this.dom
        }
        opt.load_data = function(data) {
            this.data = data
            return this
        }
        opt.add_data = function (data) {
            this.data = this.data.push(data)
            return this
        }
        opt.remove_data = function (data) {
            var _this = this
            this.data.forEach(function (v, i) {
                if(_this.value_field(v) == _this.value_field(data)){
                    _this.data.splice(i, 1)
                }
            })
            return this
        }
        opt.clear_value = function() {
            return this.dom.val('').data('value', '')
        }
        opt.validate = function () {
            var result = true
            if(this.nonempty) {
                if(this.validate_display) {  //校验展示值
                    result = this.get_display_value() ? true : false
                } else {
                    result = this.getValue() ? true : false
                }
                if(!result) this._singleValidate()
            }
            return result
        }
        opt._rendDom = function(){
            // alert($('[data-value="'+opt.id+'"]').length)
            if($('[data-value="'+opt.id+'"]').length>0) return
            var table = $('<div class="input-drop input-filter-drop" data-value="' + opt.id + '" id="' + opt.id + '-drop"></div>')
            $('body').append(table)
        }
        var filter = function(arg) {
            arg = arg || {}
            var input = $(this)
            if (input.hasClass('disabled')) {
                return
            }
            input.data().isBlur = true
            var value = input.data('value')
            var display = input.val().trim()
            if (!value || !display) {
                value = ''
            }
            var drop = $('#' + opt.id + '-drop')
            var par = opt

            var width = input.outerWidth()
            var height = input.outerHeight()
            var offset = input.offset()
            var popupFlag = input.closest('.popup').length>0?true:false
            var popupDropFlag = input.closest('.popup-drop').length>0?true:false
            if(popupDropFlag) {
                popupFlag = true
            }
            var top = offset.top + height
            if(popupFlag){
                top = top - $(document).scrollTop()
            }
            drop.css({
                'top': top,
                'left': offset.left,
                'min-width': width,
                'max-width': '400px',
                'position':popupFlag ? 'fixed' : 'absolute'
            })
            drop.empty()
            if ( par.data.length <= 0) {
                if(opt.allow_enter){
                    drop.html('').hide()
                } else if(arg.showLoadingIcon) {
                    drop.html('<div><i class="fa fa-refresh fa-spin select-loading"></i></div>').show()
                } else {
                    drop.html('<div>没有匹配结果</div>').show()
                }
            }

            var res
            if(opt.no_filter_when_focus && arg.eventType === 'focus') {
                res = par.data
            } else {
                res = par.filter(par.data, display, value)
            }

            if (res.length <= 0) {
                if(opt.allow_enter){
                    drop.html('').hide()
                } else if(arg.showLoadingIcon) {
                    drop.html('<div><i class="fa fa-refresh fa-spin select-loading"></i></div>').show()
                } else {
                    drop.html('<div>没有匹配结果</div>').show()
                }
                return
            }
            var out = []
            $.each(res, function(_, v) {
                out.push('<div data-value="' + par.value_field(v) + '">' + par.display_field(v) + '</div>')
            })
            if(!opt.eventName){
                drop.html(out.join('')).show().children('div').on('click', function(e) {
                    var v = $(this)
                    var id = v.parent().data().value
                    opt.dom.val(v.text()).data('value', v.data().value)
                    _.event.fire(id, 'change', v.data().value)
                    v.parent().hide()
                    e.preventDefault()
                    //================
                    var dropId = v.parent().attr("data-value");
                    var drop_value = v.attr("data-value");
                    var input_type = $("#"+dropId).attr("data-type");
                    if(input_type == "grid"){
                        var html = '<input class="'+$("#"+dropId).attr("class")+'" id="'+dropId+'" data-type="grid" type="text">';
                        var dropValue = $("#"+dropId).val();
                        var span = $("#"+dropId).parent("div").prev("span");
                        span.attr("data-value",drop_value).html(dropValue).show();
                        $("#"+dropId).parent("div").remove();
                        span.parent("td").append(html);
                        $("#"+dropId+"-drop").remove();
                    }
                    //================
                    if(opt.dom.hasClass("input-eidt")){
                        opt.dom.data().isBlur = false
                        opt.dom.blur()
                    }
                    if(opt.event_chage){
                        opt.event_chage();
                    }
                })
            }else {
                drop.html(out.join('')).show().children('div').on('click', _.event[opt.eventName])
            }
        }

        opt._bindEvent = function () {
            var input_listener = function(evt) {
                if($(this.id).isAsync){
                    $(document).trigger('click')
                    $(this).data('value','')
                    $(this.id).data = []
                    // 此处传一个参数： true, 当点击输入框后会出现一个loading图标。
                    filter.apply(this, [true])
                    opt.delayCallback(this, filter)
                } else {
                    filter.apply(this)
                }
                if(evt) evt.preventDefault()
            }

            if(window.attachEvent && document.getElementById(opt.id).onpropertychange){
                document.getElementById(opt.id).onpropertychange = input_listener
            }else{
                opt.dom.on('input',input_listener)
                opt.dom.on('click',input_listener)
            }


            var drop = $('#' + opt.id + '-drop')
            var scrollCalculate = function(){
                if(!drop.find('.input-item-selected').offset()) return
                var selectedTop = drop.find('.input-item-selected').offset().top
                var dropTop = drop.offset().top
                var selectedOutHeight = drop.find('.input-item-selected').outerHeight()
                var dropOutHeight = drop.outerHeight()
                if (selectedTop - dropTop + selectedOutHeight <= 1) {
                    var height = 0
                    $.each($('.input-item-selected').prevAll(), function (_, item) {
                        height += $(item).outerHeight()
                    })
                    $('.input-drop').scrollTop(height)
                } else if (selectedTop - dropTop + selectedOutHeight > dropOutHeight) {
                    var height = 0
                    $.each($('.input-item-selected').prevAll(), function (_, item) {
                        height += $(item).outerHeight()
                    })
                    $('.input-drop').scrollTop(height + selectedOutHeight - dropOutHeight)
                }
            }

            opt.dom.on('keyup', function(evt) {
                if (evt.which == 13) {
                    if(event.target.tagName != 'TEXTAREA') {
                        // enter
                        if(drop.find('.input-item-selected').length > 0){
                            drop.children('.input-item-selected').eq(0).trigger('click')
                        } else {
                            if($(this).hasClass('needEmpty')) {
                                drop.children('div').eq(0).trigger('click')
                            }
                        }
                    }
                    evt.preventDefault()
                } else if (evt.which == 9) {
                    // tab
                    if(opt.dom.val()) {
                        drop.children('div').eq(0).trigger('click')
                    }
                } else {
                    if ((evt.which < 48 && evt.which > 9) || (evt.which > 105 && evt.which < 112) || (evt.which > 123 && evt.which < 223)) {
                        evt.preventDefault()
                    }
                }
            }).on('keydown', function(evt) {
              // console.log("222")
                var value = $(this).data().value;
                $(this).data('value','')
                var drop = $('#' + $(this).attr('id') + '-drop')
                var children = drop.children('div')
                if (evt.which == 9) {
                    // tab
                    if(opt.dom.val() && (drop[0] && drop[0].style && drop[0].style.display!='none')) {
                        drop.children('div').eq(0).trigger('click')
                    } else {
                        opt.dom.data().value = value;
                        drop.hide()
                    }
                } else if (evt.which == 38) {
                    // arrow top
                    if(evt.target.tagName == 'TEXTAREA') return
                    var total = children.length
                    for (var i = 0; i < total; i++) {
                        if (i > 0 && children.eq(i).hasClass('input-item-selected')) {
                            children.eq(i).removeClass('input-item-selected')
                            children.eq(i - 1).addClass('input-item-selected')
                            break;
                        }
                    }
                    scrollCalculate()
                    evt.preventDefault()
                } else if (evt.which == 40) {
                    if(evt.target.tagName == 'TEXTAREA') return
                    var total = children.length
                    for (var i = 0; i < total; i++) {
                        if (i < total - 1 && children.eq(i).hasClass('input-item-selected')) {
                            children.eq(i).removeClass('input-item-selected')
                            children.eq(i + 1).addClass('input-item-selected')
                            break;
                        } else {
                            if(drop.find('.input-item-selected').length>0) continue
                            children.eq(0).addClass('input-item-selected')
                            break;
                        }
                    }
                    scrollCalculate()
                    evt.preventDefault()
                }
            }).on('click', function(e) {
                e.preventDefault()
                return false
            }).on('focus', function(e) {
              // console.log("3")
                if(opt.isDelay) { // isDelay：true,当输入框获得焦点时，从数据库拿取下拉框中的值
                    $(document).trigger('click')
                    $(this).data('value','')
                    $(this.id).data = []
                    // 此处传一个参数showLoadingIcon:true, 当点击输入框后会出现一个loading图标。
                    filter.call(this, {showLoadingIcon:true})
                    opt.delayCallback(this, filter)
                } else {
                    $(document).trigger('click')
                    filter.call(this, {eventType: 'focus'})
                }
                e.preventDefault();
            }).on('blur',function (e) {
              // console.log("4")
                if(!opt.allow_enter && !opt.getValue()){
                    opt.dom.val('')
                    opt.dom.data("value","");
                }
                if(opt.event_chage){
                    opt.event_chage();
                }
            }).on('mouseup', function() {
              // console.log("5")
                $(this).select()
            })
        }

        opt._initConfig(config)
        opt.init()
        opt._rendDom()
        opt._bindEvent()

        return opt
    }
    return input
})
