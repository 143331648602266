define(function(require, exports, module) {
    var paging = function(config) {
        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {pageData : {
            pageSize: 1,
            pageTotal: 5,
            currentTotal: 50,
            total: 100
        }, pagingListQty: 5}, config)
        opt.init = function() {
            var _ = this;
            _.pagingInit(_.pageData);
        }

        opt.pagingInit = function (pageData){
            var _ = this;
            //解决当 _.pagingListQty小于0的问题
            _.pagingListQty = _.pagingListQty <= 0 ? 5 : _.pagingListQty;


            Handlebars.registerHelper("activePage",function(value){
                if(pageData.pageSize === value){
                    return 'active'
                }
            })

            Handlebars.registerHelper("currentSelected",function(value){
                if(pageData.currentTotal-0 === value){
                    return "selected"
                }
            })



            pageData.pagingList = [];
            // 页码列 起始 index
            var i = 1;
            var pageTotal = pageData.pageTotal;
            if (pageTotal <= _.pagingListQty) {
                while (pageTotal--) {
                    pageData.pagingList[pageData.pagingList.length] = i;
                    i++;
                }
            } else if(pageData.pageSize <= Math.ceil(_.pagingListQty/2)) {
                while (_.pagingListQty--) {
                    pageData.pagingList[pageData.pagingList.length] = i;
                    i++;
                }
            } else if(pageData.pageSize + Math.ceil(_.pagingListQty/2) > pageTotal) {
                i = pageTotal - _.pagingListQty + i;
                while (_.pagingListQty--) {
                    pageData.pagingList[pageData.pagingList.length] = i;
                    i++;
                }
            } else {
                i = pageData.pageSize - Math.ceil(_.pagingListQty/2) + i;
                while (_.pagingListQty--) {
                    pageData.pagingList[pageData.pagingList.length] = i;
                    i++;
                }
            }
            pageData.pageTotal -= 0;
            pageData.pageSize -= 0;

            $.get('../template/paging.tpl',function (tpl) {
                _.pagingHLB = Handlebars.compile(tpl);
                $('[esapp-id='+ _.containerId +']').html(_.pagingHLB(pageData));
                var currentPage = $('#'+ window.esapp.route.current + ' .serial-number.active').text()

                if(currentPage == 1){
                    $('.prev-page').find('.paging-left').addClass('notAllowed');
                }
                if(currentPage == pageData.pageTotal){
                    $('.next-page').find('.paging-right').addClass('notAllowed');
                }
                $('.serial-number').on('click', function (){
                    if(!$(this).hasClass('active')){
                        _.paginationCb($(this).text())
                    }
                })

                $('.next-page').on('click', function (){
                    if($(this).children().hasClass('notAllowed')){
                        return false;
                    }
                    if(pageData.pageTotal > pageData.pageSize){
                        pageData.pageSize += 1
                    }
                    _.paginationCb(pageData.pageSize)
                })

                $('.prev-page').on('click', function (){
                    if($(this).children().hasClass('notAllowed')){
                        return false;
                    }
                    if(1 < pageData.pageSize){
                        pageData.pageSize -= 1
                    }
                    _.paginationCb(pageData.pageSize)
                })

                $('.paging-pre-select').on('change', function (){
                    _.pageSizeCb($(this).val())
                })
            })
        }

        opt.init();
        return opt
    }
    return paging
})
