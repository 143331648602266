define(function(require, exports, module) {

    var date = function(config) {
        var configCn = {
            dateFormat: 'yy-mm-dd',
            yearSuffix: '年',
            todayHighlight: true,
            monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
            dayNames: [ '星期一', '星期二', '星期三', '星期四', '星期五', '星期六','星期日'],
            dayNamesShort: [ '周一', '周二', '周三', '周四', '周五', '周六','周日'],
            dayNamesMin: ['一', '二', '三', '四', '五', '六','日']
        }

        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {
            name: 'date',
            init: function() {
                this.defaultInit()
                this.bindValidation()
                this.defaultChangeEvt()
                var dom
                if (config.hasTime) {
                    configCn.dayNames = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                    configCn.dayNamesShort = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                    configCn.dayNamesMin = ['日','一', '二', '三', '四', '五', '六'];
                    config = _.merge(config, configCn, {
                        onClose: function(text) {
                            var reg = /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
                            var n = $(this).val();
                            if (!reg.test(n)) {
                                $(this).val("");
                                return
                            }
                            if (config.callbackFunction) {
                                config.callbackFunction(text)
                            }
                        },
                        timeText: "时间",
                        closeText: "关闭",
                        currentText: "当前时间",
                        timeFormat: 'HH:mm:00',
                        controlType: 'select',
                        oneLine: true
                    })
                    dom = this.dom.datetimepicker(config)
                } else if (config.dateType == 'time') {
                    configCn.dayNames = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                    configCn.dayNamesShort = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                    configCn.dayNamesMin = ['日','一', '二', '三', '四', '五', '六'];
                    config = _.merge(config,configCn,{
                        onClose: function(text){
                            if(config.callbackFunction){
                                config.callbackFunction(text)
                            }
                        },
                        timeText:"时间",
                        closeText:"关闭",
                        timeOnlyTitle: '选择时间',
                        timeFormat:'HH:mm:00',
                        controlType: 'select',
                        stepMinute: 10,
                        oneLine: true
                    })
                    dom = this.dom.timepicker(config)
                }else if (config.dateType == 'time-step-one') {
                    configCn.dayNames = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                    configCn.dayNamesShort = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                    configCn.dayNamesMin = ['日','一', '二', '三', '四', '五', '六'];
                    config = _.merge(config,configCn,{
                        onClose: function(text){
                            if(config.callbackFunction){
                                config.callbackFunction(text)
                            }
                        },
                        timeText:"时间",
                        closeText:"关闭",
                        timeOnlyTitle: '选择时间',
                        timeFormat:'HH:mm:00',
                        controlType: 'select',
                        stepMinute: 1,
                        oneLine: true
                    })
                    dom = this.dom.timepicker(config)
                } else if (config.dateType == 'month') {
                    if (!$.fn.newDatePicker.dates["zh-CN"]) {
                        $.fn.newDatePicker.dates["zh-CN"] = { days: [ "星期一", "星期二", "星期三", "星期四", "星期五", "星期六","星期日"], daysShort: ["周一", "周二", "周三", "周四", "周五", "周六","周日"], daysMin: [ "一", "二", "三", "四", "五", "六","日"], months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"], monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"], today: "今天", clear: "清除", format: "yyyy年mm月dd日", titleFormat: "yyyy年mm月", weekStart: 0 }
                    }
                    config = _.merge(config, configCn, {
                        format: "yyyy-mm",
                        language: "zh-CN",
                        startView: 1,
                        minViewMode: 1,
                        autoclose: true
                    })
                    dom = this.dom.newDatePicker(config)
                } else {
                    if (!$.fn.newDatePicker.dates["zh-CN"]) {
                        $.fn.newDatePicker.dates["zh-CN"] = { days: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六","星期日"], daysShort: ["周一", "周二", "周三", "周四", "周五", "周六","周日"], daysMin: ["一", "二", "三", "四", "五", "六","日"], months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"], monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"], today: "今天", clear: "清除", format: "yyyy年mm月dd日", titleFormat: "yyyy年mm月", weekStart: 0 }
                    }
                    config = _.merge(config, configCn, {
                        language: "zh-CN",
                        autoclose: true,
                        todayBtn: "linked",
                        clearBtn: true,
                        daysOfWeekHighlighted: "0,6"
                    })
                    config = _.merge(config, configCn)
                    this.dom.attr('data-date-format', config.format || 'yyyy-mm-dd')
                    dom = this.dom.newDatePicker(config)
                    dom.on('change', function() {
                        var n = $(this).val();
                        var reg = /^((?:19|20)\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
                        if (!reg.test(n)) {
                            $(this).val("");
                            return
                        }
                    })
                }
                dom.on('change', function() {
                    var value = $(this).val();
                    if (config.onChange)
                        config.onChange(value);
                })
                dom.on('click', function() {
                    if(config.dateType == 'time'){//点击时分秒input的时候，强制选择00:00:00
                        //当config.dateType == 'time'的时候，似乎setvalue不管用.....
                        if( $(".ui_tpicker_hour_slider select option").eq(0)){
                            $(".ui_tpicker_hour_slider select option").eq(0).attr("selected",'true')
                        }
                    }
                })
                return this
            },
            setValue: function(val) {
                if (config.dateType == 'month') {
                    this.dom.newDatePicker('update', val);
                } else if (config.hasTime) {
                    this.dom.datetimepicker('setDate', val)
                } else {
                    // this.dom.datepicker('setDate',val)
                    this.dom.newDatePicker('setDate', val)
                }
            }
        }, config)
        return opt.init()
    }
    return date
})