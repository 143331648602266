define(function (require, exports, module) {
    var input = function (config) {
        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {
            name: 'radio'
        }, config)
        
        opt._renderDom = function () {
            var _this = this
            this.dom.hide();
            var html = "";
            if (opt.data) {
                opt.data.forEach(function(v, i) {
                    html += '<div class="radio-item"><input type="radio" name="' + opt.dom.attr("name") + '" id="' + v.id + '"/><label>' + v.text + '</label></div>'
                })
            }
            this.dom.siblings(".radio-group").remove()
            this.dom.after('<div class="radio-group" name="' + this.dom.attr("name") + '">' + html + "</div>");
            
            this.dom.siblings(".radio-group").find(".radio-item").on("click", function() {
                if($(this).find('.disabled').length > 0)return
                $(this).parent().find(".radio-item").removeClass("current-radio");
                $(this).addClass("current-radio");
                $(this).find("input").prop("checked", true);
                opt.dom.val(_this.getValue()).trigger("change")
                _.event.fire(opt.id, 'change', opt.dom.data().value)
            });
        }
        
        opt.getValue = function () {
            return this.dom.siblings(".radio-group").find(".current-radio input").attr("id")
        }

        opt.setValue = function(e) {
            $.each(this.dom.siblings(".radio-group").find(".radio-item input"), function(f, g) {
                if ($(g).attr("id") == e) {
                    $(g).trigger("click")
                }
            });
        }

        opt.init();
        opt._renderDom();

        return opt;
    }
    return input
})
